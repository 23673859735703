import {
  getStorage,
  useStorageEmulator,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

let _storage;

// let _storage;
function initStorage(firebaseApp) {
  _storage = getStorage(firebaseApp);
  if (process.env.VUE_APP_EMULATORS) {
    useStorageEmulator(_storage, "localhost", 9199);
  }
  return _storage;
}

export { uploadBytesResumable, ref, initStorage };
